import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ToggleSwitch from './ToggleSwitch'
import logo from '../media/logo_f7a823.svg';
import burgermenu_000000 from '../media/burgermenu_000000.svg';
import burgermenu_f7a823 from '../media/burgermenu_f7a823.svg';
import './Header.css';

class Header extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {isMenuToggled: false};
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openHome = this.openHome.bind(this);
        this.handleLanguageSwitchCheck = this.handleLanguageSwitchCheck.bind(this);
        document.body.classList.toggle("navbar-active", false);
    }

    toggleMenu() {
        document.body.classList.toggle("navbar-active", this.state.isMenuToggled === false);
        this.setState(prevState => ({
            isMenuToggled: !prevState.isMenuToggled
        }));
    }

    closeMenu() {
        document.body.classList.toggle("navbar-active", false);
        this.setState(prevState => ({
            isMenuToggled: false
        }));
    }

    openHome() {
        this.props.navigate('/');
    }

    handleLanguageSwitchCheck(value) {
        let language = 'de';
        if (value === true) {
            language = 'en';
        }
        let onLanguage = this.props.onLanguage;
        if (onLanguage && typeof onLanguage === 'function') {
            onLanguage(language);
        }
    }

    render() {
        let languageSwitchChecked = false;
        if (this.props.language && this.props.language.length > 0 && this.props.language !== 'de') {
            languageSwitchChecked = true;
        }
        return (
            <header className="App-header">
                <div className="App-header-logo">
                    <img src={logo} alt="logo" onClick={this.openHome} />
                    <span className="eurostile-condensed-heavy" onClick={this.openHome}>EBNER MEDIA &amp; MANAGEMENT GmbH</span>
                </div>
                <div className={`App-header-burgermenu${this.state.isMenuToggled ? ' active' : ''}`} onClick={this.toggleMenu}>
                    <img src={burgermenu_000000} className="normal" alt="burgermenu" />
                    <img src={burgermenu_f7a823} className="hover" alt="burgermenu-hover" />
                </div>
                <nav className={`App-header-navbar${this.state.isMenuToggled ? ' active' : ''}`}>
                    <div className="App-header-navbar-before">
                        <div className="App-header-navbar-before-inner"></div>
                    </div>
                    <div className="App-header-navbar-inner">
                        <ul>
                            <li className="App-header-navbar-li-services">
                                <NavLink to="/services" onClick={this.closeMenu}>{this.props.t('Common.Services')}</NavLink>
                            </li>
                            <br className="App-header-navbar-br-services" />
                            <li className="App-header-navbar-li-references">
                                <NavLink to="/references" onClick={this.closeMenu}>{this.props.t('Common.References')}</NavLink>
                            </li>
                            <br className="App-header-navbar-br-references" />
                            <li className="App-header-navbar-li-posscreen">
                                <NavLink to="/posscreen" onClick={this.closeMenu}>{this.props.t('Common.POSSCREEN')}</NavLink>
                            </li>
                            <br className="App-header-navbar-br-posscreen" />
                            {/*<li className="App-header-navbar-li-career">
                                <NavLink to="/career" onClick={this.closeMenu}>{this.props.t('Common.Career')}</NavLink>
                                <div className="App-header-navbar-indicator">+1</div>
                            </li>
                            <br className="App-header-navbar-br-career" />*/}
                            <li className="App-header-navbar-li-team">
                                <NavLink to="/team" onClick={this.closeMenu}>{this.props.t('Common.Team')}</NavLink>
                            </li>
                            <br className="App-header-navbar-br-team" />
                            <li className="App-header-navbar-li-companyhistory">
                                <NavLink to="/companyhistory" onClick={this.closeMenu}>{this.props.t('Common.CompanyHistory')}</NavLink>
                            </li>
                            <br className="App-header-navbar-br-companyhistory" />
                            <li className="App-header-navbar-li-contact">
                                <NavLink to="/contact" onClick={this.closeMenu}>{this.props.t('Common.Contact')}</NavLink>
                            </li>
                        </ul>
                        <div className="App-header-navbar-language">
                            <ToggleSwitch id="languageswitch" value1="DE" value2="EN" checked={languageSwitchChecked} onCheck={this.handleLanguageSwitchCheck} />
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default withTranslation()(function HeaderUseNavigate(props) {
    const navigate = useNavigate();
    return <Header {...props} navigate={navigate} />
})

Header.defaultProps = {
    language: 'de',
    onLanguage: undefined
};

Header.propTypes = {
    language: PropTypes.string,
    onLanguage: PropTypes.func
};