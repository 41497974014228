import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo, getLocationHashId } from '../App'
import { withTranslation } from 'react-i18next';
import BookishContainer from '../component/BookishContainer'
import Footer from '../component/Footer'
import HomeIntro from './HomeIntro'
import HomeServices from './HomeServices'
import HomePOSSCREEN from './HomePOSSCREEN'
import HomeReferences from './HomeReferences'
//import HomeCareer from './HomeCareer'
import HomeTeam from './HomeTeam'
import HomeCompanyHistory from './HomeCompanyHistory'
import './Home.css';

class Home extends React.Component {

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
    }

    render() {
        return (
            <div className="App-home">
                {/* Intro */}
                <BookishContainer id="intro" backgroundColor="#FFFFFF" bottomRightBorderBackgroundColor="#95A6B1">
                    <HomeIntro />
                </BookishContainer>
                {/* Services */}
                <BookishContainer id="services" header={this.props.t('Common.Services')} backgroundColor="#95A6B1" topLeftBorderBackgroundColor="#FFFFFF" bottomRightBorderBackgroundColor="#B5C1C9">
                    <HomeServices />
                </BookishContainer>
                {/* References */}
                <BookishContainer id="references" header={this.props.t('Common.Customers')} backgroundColor="#B5C1C9" topLeftBorderBackgroundColor="#95A6B1" bottomRightBorderBackgroundColor="#D5DBE0">
                    <HomeReferences />
                </BookishContainer>
                {/* POS.SCREEN */}
                <BookishContainer id="posscreen" header={this.props.t('Common.POSSCREEN')} backgroundColor="#D5DBE0" topLeftBorderBackgroundColor="#B5C1C9" bottomRightBorderBackgroundColor="#FFFFFF">
                    <HomePOSSCREEN />
                </BookishContainer>
                {/* Career 
                    <BookishContainer id="career" header={this.props.t('Common.Career')} backgroundColor="#EBECF0" topLeftBorderBackgroundColor="#D5DBE0" bottomRightBorderBackgroundColor="#FFFFFF">
                        <HomeCareer />
                    </BookishContainer>
                    */
                }
                {/* Team */}
                <BookishContainer id="team" header={this.props.t('Common.Team')} backgroundColor="#FFFFFF" topLeftBorderBackgroundColor="#D5DBE0" bottomRightBorderBackgroundColor="#D5DBE0">
                    <HomeTeam />
                </BookishContainer>
                {/* Company History */}
                <BookishContainer id="companyhistory" header={this.props.t('Common.CompanyHistory')} backgroundColor="#D5DBE0" topLeftBorderBackgroundColor="#FFFFFF" bottomRightBorderBackgroundColor="#000000">
                    <HomeCompanyHistory />
                </BookishContainer>
                {/* Contact */}
                <BookishContainer id="contact" header={this.props.t('Common.Contact')} textColor="#FFFFFF" backgroundColor="#000000" topLeftBorderBackgroundColor="#D5DBE0">
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(Home);

Home.defaultProps = {
    scrollto: ''
};

Home.propTypes = {
    scrollto: PropTypes.string
}