import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import i18next from 'i18next';
import Header from './component/Header';
import Home from './home/Home';
import Services from './services/Services';
/* import Products from './products/Products'; */
import References from './references/References';
import Career from './career/Career';
import Datenschutz from './component/Datenschutz';
import Impressum from './component/Impressum';
import './App.css';

function App() {

  // we could also define trackEvent from useMatomo to handle track custom events
  const { trackPageView, enableLinkTracking } = useMatomo()
  enableLinkTracking()
  React.useEffect(() => {
    trackPageView()
  }, [trackPageView])

  // browser language
  let language = i18next.language;
  if (language && language.length > 0) {
    language = language.toLowerCase();
    if (language.startsWith('de-') || language.startsWith('en-')) {
      language = language.substring(0, 2);
    }
  }
  // route language
  let location = getLocation();
  if (location && location.pathname && location.pathname.length > 0) {
    let pathname = location.pathname.toLowerCase();
    if (pathname === '/de' || pathname === '/de/') {
      language = 'de';
    } else if (pathname === '/en' || pathname === '/en/') {
      language = 'en';
    }
  }
  // fallback language
  if (language !== 'de' && language !== 'en') {
    language = 'de';
  }
  // set language
  setLanguage(language);
  const onLanguage = function (value) { setLanguage(value); };

  return (
    <BrowserRouter>
      <div className="App">
        <Header language={language} onLanguage={onLanguage} />
        <div className="App-body">
          <Routes>
            <Route path="services" element={<Home scrollto="services" />} />
            <Route path="service-details" element={<Services />} />
            <Route path="posscreen" element={<Home scrollto="posscreen" />} />
            <Route path="references" element={<Home scrollto="references" />} />
            <Route path="reference-details" element={<References />} />
            <Route path="career" element={<Career />} />
            <Route path="team" element={<Home scrollto="team" />} />
            <Route path="companyhistory" element={<Home scrollto="companyhistory" />} />
            <Route path="contact" element={<Home scrollto="contact" />} />
            <Route path="datenschutz" element={<Datenschutz />} />
            <Route path="agb" element={<Impressum />} />
            <Route path="impressum" element={<Impressum />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;

/* ----------------------------------- */
/* ------------- Helpers ------------- */
/* ----------------------------------- */

export function scrollTo(id) {
  if (id && id.length > 0) {
    let element = document.getElementById(id);
    if (element) {
      setTimeout(() => { element.scrollIntoView({ behavior: 'smooth' }); }, 300)
      return true;
    }
    console.error('App.scrollTo("' + id + '"): Unkown id!');
  }
  return false;
}

export function getLocation() {
  if (window && window.location) {
    return window.location;
  }
  return undefined;
}

export function isLocalhost() {
  let location = getLocation();
  if (location && location.hostname && location.hostname.length > 0) {
    let hostname = location.hostname;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      return true;
    }
  }
  return false;
}

export function getLocationHashId() {
  let location = getLocation();
  if (location && location.hash && location.hash.length > 0) {
    let hash = location.hash;
    let match = /#([a-zA-Z])+$/.test(hash);
    if (match === true) {
      return hash.substring(1);
    }
  }
  return undefined;
}

export function getPlatform() {
  let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  return platform;
}

export function isWindows() {
  let platform = getPlatform();
  if (platform && platform.length > 0) {
    platform = platform.toLowerCase();
    return platform.indexOf('win') > -1;
  }
  return false;
}

export function setLanguage(language) {
  if (!(language && language.length > 0)) {
    console.log('Cannot set undefined language!');
    return;
  }
  if (isLocalhost()) {
    console.log('Set language to ' + language);
  }
  document.getElementById('root').className = language;
  i18next.changeLanguage(language);
}

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name) {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function setTitle(subtitle) {
  if (subtitle != null)
    document.title = 'EMM - ' + subtitle;
  else
    document.title = 'Ebner Media & Management GmbH';
};