import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo, getLocationHashId } from '../App'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ArrowButton from '../component/ArrowButton'
import BookishContainer from '../component/BookishContainer'
import BubbleContainer from '../component/BubbleContainer'
//import Carousel from '../component/Carousel'
import Slogan from '../component/Slogan'
import Footer from '../component/Footer'
import leistungsdreieck_text from '../media/leistungsdreieck_text.svg';
import leistungsdreieck_000000 from '../media/leistungsdreieck_000000.svg';
import webapp_ffffff from '../media/webapp_ffffff.svg';
import webapp_000000 from '../media/webapp_000000.svg';
import mobileapp_ffffff from '../media/mobileapp_ffffff.svg';
import mobileapp_000000 from '../media/mobileapp_000000.svg';
import desktopapp_ffffff from '../media/desktopapp_ffffff.svg';
import desktopapp_000000 from '../media/desktopapp_000000.svg';
import cloudsystem_ffffff from '../media/cloudsystem_ffffff.svg';
import professionalservices_ffffff from '../media/professionalservices_ffffff.svg';
import './Services.css';

class Services extends React.Component {

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
    }

    render() {
        return (
            <div className="App-services">
                <div className='App-services-top-right-corner'>
                    <div className='App-services-top-right-corner-inner'></div>
                </div>

                {/* Slogan */}
                <BookishContainer className="App-slogan-container">
                    <Slogan className="App-slogan" />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer id="intro" header={this.props.t('Common.Services')} subheader={this.props.t('Services.SubHeader')} backgroundColor="#95A6B1" topLeftBorderBackgroundColor="#FFFFFF">
                    <IntroSection />
                </BookishContainer>

                {/* Professional Services */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="professionalservices" header={this.props.t('Common.ProfessionalServices')} iconsrc={professionalservices_ffffff}>
                    <ProfessionalServicesSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <ProfessionalServicesDetailsSection />
                </BookishContainer>

                {/* Mobile Apps */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="mobileapps" header={this.props.t('Common.MobileApps')} iconsrc={mobileapp_ffffff}>
                    <MobileAPPSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <MobileAPPDetailsSection />
                </BookishContainer>

                {/* Web Apps */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="webapps" header={this.props.t('Common.WebApps')} iconsrc={webapp_ffffff}>
                    <WebAPPSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <WebAPPDetailsSection />
                </BookishContainer>

                {/* Desktop Apps */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="desktopapps" header={this.props.t('Common.DesktopApps')} iconsrc={desktopapp_ffffff}>
                    <DesktopAppSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <DesktopAppDetailSection />
                </BookishContainer>

                {/* Cloud System */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="cloudsystems" header={this.props.t('Common.CloudSystems')} iconsrc={cloudsystem_ffffff}>
                    <CloudSystemSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <CloudSystemDetailsSection />
                </BookishContainer>

                {/* Contact */}
                <BookishContainer id="contact" header="" textColor="#FFFFFF" backgroundColor="#000000" topLeftBorderBackgroundColor="#D5DBE0">
                    <Footer hasDownsize />
                </BookishContainer>
            </div>
        );
    }
}

Services.defaultProps = {
    scrollto: ''
};

Services.propTypes = {
    scrollto: PropTypes.string
}

function IntroSection() {
    return (
        <div className="App-services-intro">
            <img src={leistungsdreieck_text} alt="leistungsdreieck" />
        </div>
    );
}

function MobileAPPSection() {
    return (
        <div className="App-services-mobileapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.MobileApps.Header') }}></span>
            <CrossPlatformDevelopment hasmobileapp={false} haswebapp={true} hasdesktopapp={true} />
        </div>
    );
}

function MobileAPPDetailsSection() {
    return (
        <div className="App-services-details App-services-mobileapp-details">
            <div className="left">
                <img src="/images/references/steigein_fahrapp.webp" alt="steigein_fahrapp" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.MobileApps.Content') }}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/reference-details#mobileapps" />
                </div>
            </div>
        </div>
    );
}

function WebAPPSection() {
    return (
        <div className="App-services-webapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.WebApps.Header') }}></span>
            <CrossPlatformDevelopment hasmobileapp={true} haswebapp={false} hasdesktopapp={true} />
        </div>
    );
}

function WebAPPDetailsSection() {
    return (
        <div className="App-services-details App-services-webapp-details">
            <div className="left">
                <img src="/images/references/bmvit.webp" alt="bmvit" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.WebApps.Content') }}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/reference-details#webapps" />
                </div>
            </div>
        </div>
    );
}

function DesktopAppSection() {
    return (
        <div className="App-services-desktopapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.DesktopApps.Header') }}></span>
            <CrossPlatformDevelopment hasmobileapp={true} haswebapp={true} hasdesktopapp={false} />
        </div>
    );
}

function DesktopAppDetailSection() {
    return (
        <div className="App-services-details App-services-desktopapp-details">
            <div className="left">
                <img src="/images/references/SpringerFachmedien_DesktopApp_1400x1080.webp" alt="Springer Fachmedien" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.DesktopApps.Content') }}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/reference-details#desktopapps" />
                </div>
            </div>
        </div>
    );
}

function CloudSystemSection() {
    return (
        <div className="App-services-cloudsystem">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Header') }}></span>
        </div>
    );
}

function CloudSystemDetailsSection() {
    return (
        <div className="App-services-details App-services-cloudsystem-details">
            <span>{i18next.t('Services.CloudSystems.SubHeader')}</span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Security') }}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Flexibility') }}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Expandable') }}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Costs') }}></span>
            <div className='ArrowButton-container'>
                <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/reference-details#cloudsystems" />
            </div>
        </div>
    );
}

function ProfessionalServicesSection() {
    return (
        <div className="App-services-professionalservices">
            <span>{i18next.t('Services.ProfessionalServices.Header')}</span>
        </div>
    );
}

function ProfessionalServicesDetailsSection() {
    return (
        <div className="App-services-details App-services-professionalservices-details">
            <div className="left">
                <img src="/images/references/ProfessionalServices_1400x1080.webp" alt="Professional Services" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.ProfessionalServices.Content') }}></span>
            </div>
        </div>
    );
}

function CrossPlatformDevelopment(props) {
    let mobileAPPDiv;
    if (props.hasmobileapp === true || props.hasmobileapp === 'true' || props.hasmobileapp === 'True') {
        mobileAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('mobileapps')}>
                <img src={mobileapp_000000} alt="mobileapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.MobileApp')}</span>
            </div>
        );
    }
    let webAPPDiv;
    if (props.haswebapp === true || props.haswebapp === 'true' || props.haswebapp === 'True') {
        webAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('webapps')}>
                <img src={webapp_000000} alt="webapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.WebApp')}</span>
            </div>
        );
    }
    let desktopAPPDiv;
    if (props.hasdesktopapp === true || props.hasdesktopapp === 'true' || props.hasdesktopapp === 'True') {
        desktopAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('desktopapps')}>
                <img src={desktopapp_000000} alt="webapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.DesktopApp')}</span>
            </div>
        );
    }
    return (
        <div className="App-services-crossplatform">
            <div className="App-services-crossplatform-header">{i18next.t('Services.CrossPlatform.Header')}</div>
            {mobileAPPDiv}
            {webAPPDiv}
            {desktopAPPDiv}
        </div>
    );
}

export default withTranslation()(Services);