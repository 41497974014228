import React from 'react';
import { withTranslation } from 'react-i18next';
import ArrowButton from '../component/ArrowButton'
import logo_newsrodeo_mobile from '../media/logo_newsrodeo_mobile.svg';
import logo_newsrodeo_desktop from '../media/logo_newsrodeo_desktop.svg';
import logo_iot_mobile from '../media/logo_iot_mobile.svg';
import logo_iot_desktop from '../media/logo_iot_desktop.svg';
import logo_infopop_mobile from '../media/logo_infopop_mobile.svg';
import logo_infopop_desktop from '../media/logo_infopop_desktop.svg';
import logo_passengerinfo_mobile from '../media/logo_passengerinfo_mobile.svg';
import logo_passengerinfo_desktop from '../media/logo_passengerinfo_desktop.svg';
import './HomePOSSCREEN.css';

class HomePOSSCREEN extends React.Component {

    constructor(props) {
        super(props);
        this.moreClick = this.moreClick.bind(this);
    }

    moreClick() {
        window.open("https://www.posscreen.at/", "_blank").focus();
    }

    render() {
        return (
            <div className="App-home-posscreen">
                <div className="App-home-posscreen-header">{this.props.t('HomePOSSCREEN.Header')}</div>
                <div className="App-home-posscreen-logo-container">
                    <div className="App-home-posscreen-logo">
                        <img className="mobile" src={logo_newsrodeo_mobile} alt="newsrodeo mobile" />
                        <img className="desktop" src={logo_newsrodeo_desktop} alt="newsrodeo desktop" />
                        <div className="App-home-posscreen-logo-text">{this.props.t('HomePOSSCREEN.NewsRodeo')}</div>
                    </div>
                    <div className="App-home-posscreen-logo">
                        <img className="mobile" src={logo_passengerinfo_mobile} alt="passengerinfo mobile" />
                        <img className="desktop" src={logo_passengerinfo_desktop} alt="passengerinfo desktop" />
                        <div className="App-home-posscreen-logo-text">{this.props.t('HomePOSSCREEN.PassengerInfo')}</div>
                    </div>
                    <div className="App-home-posscreen-logo">
                        <img className="mobile" src={logo_iot_mobile} alt="iot mobile" />
                        <img className="desktop" src={logo_iot_desktop} alt="iot desktop" />
                        <div className="App-home-posscreen-logo-text">{this.props.t('HomePOSSCREEN.IoT')}</div>
                    </div>
                </div>
                <div style={{textAlign: 'right'}}>
                    <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} onClick={this.moreClick} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomePOSSCREEN);