import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { scrollTo, getLocationHashId, setTitle } from '../App'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import BookishContainer from '../component/BookishContainer'
import BoxContainer from '../component/BoxContainer'
import Carousel from '../component/Carousel'
import Slogan from '../component/Slogan'
import Footer from '../component/Footer'
import lottie_team from '../media/lottie_11497_interface.json';
import digitalsignage_000000 from '../media/digitalsignage_000000.svg';
import webapp_000000 from '../media/webapp_000000.svg';
import mobileapp_000000 from '../media/mobileapp_000000.svg';
import desktopapp_000000 from '../media/desktopapp_000000.svg';
import cloudsystem_000000 from '../media/cloudsystem_000000.svg';
import './References.css';

class References extends React.Component {

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
        setTitle(this.props.t('Common.References'));
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
        setTitle(this.props.t('Common.References'));
    }

    componentWillUnmount() {
        setTitle();
    }

    render() {
        return (
            <div className="App-references">
                <div className='App-references-top-right-corner'>
                    <div className='App-references-top-right-corner-inner'></div>
                </div>

                {/* Slogan */}
                <BookishContainer className="App-slogan-container">
                    <Slogan className="App-slogan" />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer id="intro" header={this.props.t('Common.References')} backgroundColor="#EBECF0" topLeftBorderBackgroundColor="#FFFFFF">
                    <IntroSection />
                </BookishContainer>

                {/* Educational Software */}
                <BookishContainer backgroundColor="#95A6B1">
                    <EducationalSoftwareSection />
                </BookishContainer>
                <BookishContainer backgroundColor="#FFFFFF">
                    <EducationalSoftwareDetailsSection />
                </BookishContainer>
                <BookishContainer backgroundColor="#95A6B1">
                    <EducationalSoftwareContentSection />
                </BookishContainer>

                {/* Mobile Apps */}
                <BoxContainer id="mobileapps" header={this.props.t('Common.MobileApps')} iconsrc={mobileapp_000000}>
                    <MobileAPPSection />
                </BoxContainer>

                {/* Web Apps */}
                <BoxContainer id="webapps" header={this.props.t('Common.WebApps')} iconsrc={webapp_000000}>
                    <WebAPPSection />
                </BoxContainer>

                {/* Desktop Apps */}
                <BoxContainer id="desktopapps" header={this.props.t('Common.DesktopApps')} iconsrc={desktopapp_000000}>
                    <DesktopAPPSection />
                </BoxContainer>

                {/* Cloud Systems */}
                <BoxContainer id="cloudsystems" header={this.props.t('Common.CloudSystems')} iconsrc={cloudsystem_000000}>
                    <CloudSystemSection />
                </BoxContainer>

                {/* Contact */}
                <BookishContainer id="contact" header="" textColor="#FFFFFF" backgroundColor="#000000" topLeftBorderBackgroundColor="#FFFFFF">
                    <Footer hasDownsize />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(References)

References.defaultProps = {
    scrollto: ''
};

References.propTypes = {
    scrollto: PropTypes.string
}

function IntroSection() {
    return (
        <div className="App-references-intro">
            <div className="animation">
                <Player autoplay loop src={lottie_team} />
            </div>
        </div>
    );
}

function EducationalSoftwareSection() {
    return (
        <div className="App-references-educationalsoftware">
            <div className="App-references-educationalsoftware-header">
                <span className="eurostile-condensed-heavy">{i18next.t('References.EducationalSoftware.Header')}</span>
            </div>
            <div className="App-references-educationalsoftware-text">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('References.EducationalSoftware.Text') }}></span>
            </div>
        </div>
    );
}

function EducationalSoftwareDetailsSection() {
    return (
        <div className="App-references-educationalsoftware-details">
            <div className="App-references-logo-container">
                <div className="App-references-logo">
                    <img src="/images/logos/TecVia.jpg" alt="TECVIA" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Vogel.png" alt="Heinrich Vogel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/WendelVerlag.png" alt="Wendel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/HE.png" alt="HE" />
                </div>
            </div>
        </div>
    );
}

function EducationalSoftwareContentSection() {
    const carouselSrc = [
        { 'path': '/images/references/TecVia_FahrenLernenKombi_1400x791.webp', 'header': 'TECVIA - Heinrich Vogel Verlag', 'text': i18next.t('References.EducationalSoftware.Content.FahrenLernen') },
        { 'path': '/images/references/SpringerFachmedien_DarbAlsalamah_1400x791.webp', 'header': 'TECVIA - Heinrich Vogel Verlag', 'text': i18next.t('References.EducationalSoftware.Content.DarbAlsalamah') },
        { 'path': '/images/references/HeinrichVogelVerlag_EasyDriver_WebApp_1400x791.webp', 'header': 'TECVIA Switzerland', 'text': i18next.t('References.EducationalSoftware.Content.EasyDriver') },
        { 'path': '/images/references/WendelVerlag_FahrApp_WebApp_1400x791.webp', 'header': 'Wendel Verlag', 'text': i18next.t('References.EducationalSoftware.Content.FahrAPP') },
        { 'path': '/images/references/HE_Lehrmittel_Steigein_DesktopApp_1400x791.webp', 'header': 'Hubert Ebner Verlag', 'text': i18next.t('References.EducationalSoftware.Content.HE') },
    ];
    return (
        <div className="App-references-educationalsoftware-content">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function MobileAPPSection() {
    const carouselSrc = [
        { 'path': '/images/references/Tecvia_FahrenLernen_MobileApp_1400x791.webp', 'header': 'TECHVIA - Heinrich Vogel Verlag', 'text': i18next.t('References.MobileApps.Springer') },
        { 'path': '/images/references/WendelVerlag_FahrApp_MobileApp_1400x791.webp', 'header': 'Wendel Verlag', 'text': i18next.t('References.MobileApps.Wendel') },
        { 'path': '/images/references/WendelVerlag_FahrApp_MobileAppTablet_1400x791.webp', 'header': 'Wendel Verlag', 'text': i18next.t('References.MobileApps.Wendel') },
        { 'path': '/images/references/HEVerlag_Steigein_MobileApp_1400x791.webp', 'header': 'Hubert Ebner Verlag', 'text': i18next.t('References.MobileApps.HE') },
    ];
    return (
        <div className="App-references-mobileapp">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
            <div className="App-references-logo-container">
                <div className="App-references-logo">
                    <img src="/images/logos/TecVia.jpg" alt="TECVIA" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Vogel.png" alt="Heinrich Vogel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/WendelVerlag.png" alt="Wendel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/HE.png" alt="HE" />
                </div>
            </div>
        </div>
    );
}

function WebAPPSection() {
    const carouselSrc = [
        { 'path': '/images/references/TecVia_FahrenLernen_WebApp_1400x791.webp', 'header': 'TECVIA - Heinrich Vogel Verlag', 'text': i18next.t('References.EducationalSoftware.Content.FahrenLernen') },
        { 'path': '/images/references/HeinrichVogelVerlag_EasyDriver_WebApp_1400x791.webp', 'header': 'TECVIA Switzerland', 'text': i18next.t('References.EducationalSoftware.Content.EasyDriver') },
        { 'path': '/images/references/WendelVerlag_FahrApp_WebApp_1400x791.webp', 'header': 'Wendel Verlag', 'text': i18next.t('References.EducationalSoftware.Content.FahrAPP') },
        { 'path': '/images/references/Bmvit_AD_1400x791.webp', 'header': 'BMVIT', 'text': i18next.t('References.WebApp.BMVIT') },
        { 'path': '/images/references/EZAgrar_WebApp_1400x791.webp', 'header': 'EZ Agrar', 'text': i18next.t('References.WebApp.EZAgrar') },
        { 'path': '/images/references/Gutwinski_Webdevelopment_1400x791.webp', 'header': 'Gutwinski', 'text': i18next.t('References.WebApp.Gutwinski') },
    ];
    return (
        <div className="App-references-webapp">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
            <div className="App-references-logo-container">
                <div className="App-references-logo">
                    <img src="/images/logos/TecVia.jpg" alt="TECVIA" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/WendelVerlag.png" alt="Wendel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/bmvit.png" alt="bmvit" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Vogel.png" alt="Heinrich Vogel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/EZAgrar.png" alt="HE" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Gutwinski.png" alt="Gutwinski" />
                </div>
            </div>
        </div>
    );
}

function DesktopAPPSection() {
    const carouselSrc = [
        { 'path': '/images/references/SpringerFachmedien_DesktopApp_1400x791.webp', 'header': 'Springer Fachmedien', 'text': i18next.t('References.DesktopApp.Springer') },
        { 'path': '/images/references/WendelVerlag_DesktopApp_1400x791.webp', 'header': 'Wendel Verlag', 'text': i18next.t('References.DesktopApp.Wendel') },
        { 'path': '/images/references/HE_Lehrmittel_Steigein_DesktopApp_1400x791.webp', 'header': 'Hubert Ebner Verlag', 'text': i18next.t('References.DesktopApp.HE') },
    ];
    return (
        <div className="App-references-desktopapp">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
            <div className="App-references-logo-container">
                <div className="App-references-logo">
                    <img src="/images/logos/TecVia.jpg" alt="TECVIA" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Vogel.png" alt="Heinrich Vogel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/WendelVerlag.png" alt="Wendel Verlag" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/HE.png" alt="HE" />
                </div>
            </div>
        </div>
    );
}

function CloudSystemSection() {
    const carouselSrc = [
        { 'path': '/images/references/TecVia_FahrenLernenKombi_1400x791.webp', 'header': 'TECVIA - Heinrich Vogel Verlag', 'text': i18next.t('References.CloudSystem.Springer') },
        { 'path': '/images/references/EZAgrar_WebApp_1400x791.webp', 'header': 'EZ Agrar', 'text': i18next.t('References.CloudSystem.EZAgrar') },
    ];
    return (
        <div className="App-references-cloudsystem">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
            <div className="App-references-logo-container">
                <div className="App-references-logo">
                    <img src="/images/logos/TecVia.jpg" alt="TECVIA" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/EZAgrar.png" alt="EZAgrar" />
                </div>
                <div className="App-references-logo">
                    <img src="/images/logos/Hennlich.png" alt="Hennlich" />
                </div>
            </div>
        </div>
    );
}
